
import { Toast } from "vant";
import { Vue, Component, Emit } from "vue-property-decorator";
import sucesstip from "./sucesstip.vue";
import { areaList } from "@vant/area-data";
import { State } from "vuex-class";
import MD5 from "md5";
@Component({
  components: {
    sucesstip,
  },
})
export default class League extends Vue {
  options = [
    {
      value: "选项1",
      label: "黄金糕",
    },
    {
      value: "选项2",
      label: "双皮奶",
    },
    {
      value: "选项3",
      label: "蚵仔煎",
    },
    {
      value: "选项4",
      label: "龙须面",
    },
    {
      value: "选项5",
      label: "北京烤鸭",
    },
  ];
  account = "";
  password = "";
  league = true;
  sucesstip = false;
  judge = true;
  areaList = areaList;
  city = [];
  show = false;
  area = {
    province: "",
    city: "",
    country: "",
  };
  @State userinfo: any;

  showPopup() {
    this.show = true;
  }

  submit(): void {
    console.log(this.userinfo);
    this.empty();
    // 判断是否为工厂版
    if (this.judge) {
      let data = {
        phone: this.account,
        password: MD5(this.password),
        province: this.area.province,
        city: this.area.city,
        country: this.area.country,
      };
      this.$post("/big_home/we_apply_cloud_factory", data).then((res: any) => {
        console.log(res);
        if (res.data.status === 1) {
          this.sucesstip = true;
        } else {
          Toast(res.data.msg);
        }
      });

      this.league = false;
    } else {
      console.log("jopgej");
    }
  }

  // 判断是否为空
  empty(): void {
    if (!this.account) {
      this.judge = false;
      Toast("请输入账号");
      return;
    }
    if (!this.password) {
      this.judge = false;
      Toast("请输入密码");
      return;
    }
    if (!this.area.province) {
      this.judge = false;
      Toast("请选择地址");
      return;
    }
  }

  // 省份选择
  confirm(data: any) {
    this.show = false;
    this.area.province = data[0].name;
    this.area.city = data[1].name;
    this.area.country = data[2].name;
  }
}
