
import deposite from "./components/deposite.vue";
import league from "./components/league.vue";
import sucesstip from "./components/sucesstip.vue";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
@Component({
  components: {
    deposite,
    league,
    sucesstip,
  },
})
export default class FindFactory extends Vue {
  searchData = "";
  value2 = 5;
  colors = ["#99A9BF", "#F7BA2A", "#FF9900"]; // 等同于 { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }
  deposite = false;
  league = false;
  evaluate = 5;
  options = [
    {
      value: "选项1",
      label: "黄金糕",
    },
    {
      value: "选项2",
      label: "双皮奶",
    },
    {
      value: "选项3",
      label: "蚵仔煎",
    },
    {
      value: "选项4",
      label: "龙须面",
    },
    {
      value: "选项5",
      label: "北京烤鸭",
    },
  ];
  value = "";
  detail = true;
  factoryList = "";
  @State userinfo: any;

  //   function
  showDeposite(): void {
    // this.detail = !this.detail;
    // this.deposite = true;
  }
  showLeague(): void {
    // 有无登录
    if (this.userinfo) {
      this.league = true;
    } else {
      this.$router.push({ name: "FF-Login" });
    }
  }
  toPersoncenter(): void {
    // 有无登录
    if (this.userinfo) {
      this.$router.push({ name: "FF-PersonCenter" });
    } else {
      this.$router.push({ name: "FF-Login" });
    }
    // this.$router.push({ name: "personCenter" });
  }

  // 搜索
  onSearch(val: string): void {
    let data = {
      brand: val,
    };

    this.$get("/big_home/we_get_cloud_factory_list", data).then((res: any) => {
      this.factoryList = res.data.data;
    });
  }

  // 获取全部列表
  getList(): void {
    let data = {
      brand: "",
    };
    this.$get("/big_home/we_get_cloud_factory_list", data).then((res: any) => {
      this.factoryList = res.data.data;
    });
  }

  mounted() {
    this.getList();
  }
}
