import { render, staticRenderFns } from "./league.vue?vue&type=template&id=008ec00c&scoped=true"
import script from "./league.vue?vue&type=script&lang=ts"
export * from "./league.vue?vue&type=script&lang=ts"
import style0 from "./league.vue?vue&type=style&index=0&id=008ec00c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008ec00c",
  null
  
)

export default component.exports